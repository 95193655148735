import lodash from "lodash";

export const answerOptions = ["A", "B", "C", "D", "E"];
export const emptyAnswer = "X";
export const kangoeroeEditionNames = {
    KANK: "Koala",
    KANS: "Springmuis",
    KANWR: "Wallaroe",
    KANWB: "Wallabie",
    KANWOM: "Wombat",
    KANQ: "Quokka",
    KANNUM: "Numbat"
};

// mock vwo-db
export const editions = [
    {
        name: "wombat",
        code: "KANWOM",
        numberOfQuestions: 15,
        answerOptions,
        emptyAnswer,
        parentEdition: "kangoeroe"
    },
    {
        name: "koala",
        code: "KANK",
        numberOfQuestions: 24,
        answerOptions,
        emptyAnswer,
        parentEdition: "kangoeroe"
    },
    {
        name: "wallabie",
        code: "KANWB",
        numberOfQuestions: 24,
        answerOptions,
        emptyAnswer,
        parentEdition: "kangoeroe"
    },
    {
        name: "springmuis",
        code: "KANS",
        numberOfQuestions: 24,
        answerOptions,
        emptyAnswer,
        parentEdition: "kangoeroe"
    },
    {
        name: "wallaroe",
        code: "KANWR",
        numberOfQuestions: 24,
        answerOptions,
        emptyAnswer,
        parentEdition: "kangoeroe"
    },
    {
        name: "quokka",
        code: "KANQ",
        numberOfQuestions: 24,
        answerOptions,
        emptyAnswer,
        parentEdition: "kangoeroe"
    },
    {
        name: "numbat",
        code: "KANNUM",
        numberOfQuestions: 24,
        answerOptions,
        emptyAnswer,
        parentEdition: "kangoeroe"
    }
];

const { keyBy } = lodash;
export const editionsByCode = keyBy(editions, "code");
export const getEditionScoring = (edition: string): { correct: number, wrong: number, blanc: number } => {
    switch (edition) {
        case "KANWOM":
        case "KANNUM":
        case "KANQ":
            return { correct: 8, wrong: 0, blanc: 2 };
        default:
            return { correct: 5, wrong: 0, blanc: 1 };
    }
};

export const passwords = (): {
    [date: string]: {
        [pwd: string]: {
            startDate: string;
            endDate: string;
        };
    };
} => ({
    "2025-03-20":{
        "ton":{
            "startDate":"2025-03-20T08:00:00",
            "endDate":"2025-03-20T09:00:00"
        },
        "sok":{
            "startDate":"2025-03-20T09:00:00",
            "endDate":"2025-03-20T10:00:00"
        },
        "sms":{
            "startDate":"2025-03-20T10:00:00",
            "endDate":"2025-03-20T11:00:00"
        },
        "lat":{
            "startDate":"2025-03-20T11:00:00",
            "endDate":"2025-03-20T12:00:00"
        },
        "fan":{
            "startDate":"2025-03-20T12:00:00",
            "endDate":"2025-03-20T13:00:00"
        },
        "fee":{
            "startDate":"2025-03-20T13:00:00",
            "endDate":"2025-03-20T14:00:00"
        },
        "oog":{
            "startDate":"2025-03-20T14:00:00",
            "endDate":"2025-03-20T15:00:00"
        },
        "koe":{
            "startDate":"2025-03-20T15:00:00",
            "endDate":"2025-03-20T16:00:00"
        },
        "ijs":{
            "startDate":"2025-03-20T16:00:00",
            "endDate":"2025-03-20T17:00:00"
        }
    },
    "2025-03-21":{
        "juf":{
            "startDate":"2025-03-21T08:00:00",
            "endDate":"2025-03-21T09:00:00"
        },
        "gsm":{
            "startDate":"2025-03-21T09:00:00",
            "endDate":"2025-03-21T10:00:00"
        },
        "gom":{
            "startDate":"2025-03-21T10:00:00",
            "endDate":"2025-03-21T11:00:00"
        },
        "lol":{
            "startDate":"2025-03-21T11:00:00",
            "endDate":"2025-03-21T12:00:00"
        },
        "mop":{
            "startDate":"2025-03-21T12:00:00",
            "endDate":"2025-03-21T13:00:00"
        },
        "dag":{
            "startDate":"2025-03-21T13:00:00",
            "endDate":"2025-03-21T14:00:00"
        },
        "opa":{
            "startDate":"2025-03-21T14:00:00",
            "endDate":"2025-03-21T15:00:00"
        },
        "mos":{
            "startDate":"2025-03-21T15:00:00",
            "endDate":"2025-03-21T16:00:00"
        },
        "pop":{
            "startDate":"2025-03-21T16:00:00",
            "endDate":"2025-03-21T17:00:00"
        }
    },
    "2025-03-24":{
        "uur":{
            "startDate":"2025-03-24T08:00:00",
            "endDate":"2025-03-24T09:00:00"
        },
        "zin":{
            "startDate":"2025-03-24T09:00:00",
            "endDate":"2025-03-24T10:00:00"
        },
        "pen":{
            "startDate":"2025-03-24T10:00:00",
            "endDate":"2025-03-24T11:00:00"
        },
        "vlo":{
            "startDate":"2025-03-24T11:00:00",
            "endDate":"2025-03-24T12:00:00"
        },
        "bal":{
            "startDate":"2025-03-24T12:00:00",
            "endDate":"2025-03-24T13:00:00"
        },
        "mug":{
            "startDate":"2025-03-24T13:00:00",
            "endDate":"2025-03-24T14:00:00"
        },
        "pap":{
            "startDate":"2025-03-24T14:00:00",
            "endDate":"2025-03-24T15:00:00"
        },
        "top":{
            "startDate":"2025-03-24T15:00:00",
            "endDate":"2025-03-24T16:00:00"
        },
        "hut":{
            "startDate":"2025-03-24T16:00:00",
            "endDate":"2025-03-24T17:00:00"
        }
    },
    "2025-03-25":{
        "wol":{
            "startDate":"2025-03-25T08:00:00",
            "endDate":"2025-03-25T09:00:00"
        },
        "pan":{
            "startDate":"2025-03-25T09:00:00",
            "endDate":"2025-03-25T10:00:00"
        },
        "aap":{
            "startDate":"2025-03-25T10:00:00",
            "endDate":"2025-03-25T11:00:00"
        },
        "hok":{
            "startDate":"2025-03-25T11:00:00",
            "endDate":"2025-03-25T12:00:00"
        },
        "zon":{
            "startDate":"2025-03-25T12:00:00",
            "endDate":"2025-03-25T13:00:00"
        },
        "bed":{
            "startDate":"2025-03-25T13:00:00",
            "endDate":"2025-03-25T14:00:00"
        },
        "zee":{
            "startDate":"2025-03-25T14:00:00",
            "endDate":"2025-03-25T15:00:00"
        },
        "fit":{
            "startDate":"2025-03-25T15:00:00",
            "endDate":"2025-03-25T16:00:00"
        },
        "bel":{
            "startDate":"2025-03-25T16:00:00",
            "endDate":"2025-03-25T17:00:00"
        }
    },
    "2025-03-26":{
        "kok":{
            "startDate":"2025-03-26T08:00:00",
            "endDate":"2025-03-26T09:00:00"
        },
        "kei":{
            "startDate":"2025-03-26T09:00:00",
            "endDate":"2025-03-26T10:00:00"
        },
        "gat":{
            "startDate":"2025-03-26T10:00:00",
            "endDate":"2025-03-26T11:00:00"
        },
        "jas":{
            "startDate":"2025-03-26T11:00:00",
            "endDate":"2025-03-26T12:00:00"
        },
        "oor":{
            "startDate":"2025-03-26T12:00:00",
            "endDate":"2025-03-26T13:00:00"
        },
        "gul":{
            "startDate":"2025-03-26T13:00:00",
            "endDate":"2025-03-26T14:00:00"
        },
        "wit":{
            "startDate":"2025-03-26T14:00:00",
            "endDate":"2025-03-26T15:00:00"
        },
        "rok":{
            "startDate":"2025-03-26T15:00:00",
            "endDate":"2025-03-26T16:00:00"
        },
        "hip":{
            "startDate":"2025-03-26T16:00:00",
            "endDate":"2025-03-26T17:00:00"
        }
    },
    "2025-03-27":{
        "gps":{
            "startDate":"2025-03-27T08:00:00",
            "endDate":"2025-03-27T09:00:00"
        },
        "kat":{
            "startDate":"2025-03-27T09:00:00",
            "endDate":"2025-03-27T10:00:00"
        },
        "mol":{
            "startDate":"2025-03-27T10:00:00",
            "endDate":"2025-03-27T11:00:00"
        },
        "uil":{
            "startDate":"2025-03-27T11:00:00",
            "endDate":"2025-03-27T12:00:00"
        },
        "zoo":{
            "startDate":"2025-03-27T12:00:00",
            "endDate":"2025-03-27T13:00:00"
        },
        "oom":{
            "startDate":"2025-03-27T13:00:00",
            "endDate":"2025-03-27T14:00:00"
        },
        "oma":{
            "startDate":"2025-03-27T14:00:00",
            "endDate":"2025-03-27T15:00:00"
        },
        "kar":{
            "startDate":"2025-03-27T15:00:00",
            "endDate":"2025-03-27T16:00:00"
        },
        "sla":{
            "startDate":"2025-03-27T16:00:00",
            "endDate":"2025-03-27T17:00:00"
        }
    },
    "2025-03-28":{
        "ree":{
            "startDate":"2025-03-28T08:00:00",
            "endDate":"2025-03-28T09:00:00"
        },
        "rug":{
            "startDate":"2025-03-28T09:00:00",
            "endDate":"2025-03-28T10:00:00"
        },
        "zak":{
            "startDate":"2025-03-28T10:00:00",
            "endDate":"2025-03-28T11:00:00"
        },
        "sap":{
            "startDate":"2025-03-28T11:00:00",
            "endDate":"2025-03-28T12:00:00"
        },
        "bos":{
            "startDate":"2025-03-28T12:00:00",
            "endDate":"2025-03-28T13:00:00"
        },
        "gek":{
            "startDate":"2025-03-28T13:00:00",
            "endDate":"2025-03-28T14:00:00"
        },
        "eik":{
            "startDate":"2025-03-28T14:00:00",
            "endDate":"2025-03-28T15:00:00"
        },
        "zus":{
            "startDate":"2025-03-28T15:00:00",
            "endDate":"2025-03-28T16:00:00"
        },
        "nek":{
            "startDate":"2025-03-28T16:00:00",
            "endDate":"2025-03-28T17:00:00"
        }
    },
    "2025-03-31":{
        "bus":{
            "startDate":"2025-03-31T08:00:00",
            "endDate":"2025-03-31T09:00:00"
        },
        "vla":{
            "startDate":"2025-03-31T09:00:00",
            "endDate":"2025-03-31T10:00:00"
        },
        "tak":{
            "startDate":"2025-03-31T10:00:00",
            "endDate":"2025-03-31T11:00:00"
        },
        "vos":{
            "startDate":"2025-03-31T11:00:00",
            "endDate":"2025-03-31T12:00:00"
        },
        "kip":{
            "startDate":"2025-03-31T12:00:00",
            "endDate":"2025-03-31T13:00:00"
        },
        "ufo":{
            "startDate":"2025-03-31T13:00:00",
            "endDate":"2025-03-31T14:00:00"
        },
        "mol":{
            "startDate":"2025-03-31T14:00:00",
            "endDate":"2025-03-31T15:00:00"
        },
        "kok":{
            "startDate":"2025-03-31T15:00:00",
            "endDate":"2025-03-31T16:00:00"
        },
        "oma":{
            "startDate":"2025-03-31T16:00:00",
            "endDate":"2025-03-31T17:00:00"
        }
    },
    "2025-04-01":{
        "gsm":{
            "startDate":"2025-04-01T08:00:00",
            "endDate":"2025-04-01T09:00:00"
        },
        "fit":{
            "startDate":"2025-04-01T09:00:00",
            "endDate":"2025-04-01T10:00:00"
        },
        "koe":{
            "startDate":"2025-04-01T10:00:00",
            "endDate":"2025-04-01T11:00:00"
        },
        "fee":{
            "startDate":"2025-04-01T11:00:00",
            "endDate":"2025-04-01T12:00:00"
        },
        "rok":{
            "startDate":"2025-04-01T12:00:00",
            "endDate":"2025-04-01T13:00:00"
        },
        "pap":{
            "startDate":"2025-04-01T13:00:00",
            "endDate":"2025-04-01T14:00:00"
        },
        "pan":{
            "startDate":"2025-04-01T14:00:00",
            "endDate":"2025-04-01T15:00:00"
        },
        "bed":{
            "startDate":"2025-04-01T15:00:00",
            "endDate":"2025-04-01T16:00:00"
        },
        "oor":{
            "startDate":"2025-04-01T16:00:00",
            "endDate":"2025-04-01T17:00:00"
        }
    },
    "2025-04-02":{
        "bus":{
            "startDate":"2025-04-02T08:00:00",
            "endDate":"2025-04-02T09:00:00"
        },
        "hok":{
            "startDate":"2025-04-02T09:00:00",
            "endDate":"2025-04-02T10:00:00"
        },
        "juf":{
            "startDate":"2025-04-02T10:00:00",
            "endDate":"2025-04-02T11:00:00"
        },
        "sms":{
            "startDate":"2025-04-02T11:00:00",
            "endDate":"2025-04-02T12:00:00"
        },
        "gek":{
            "startDate":"2025-04-02T12:00:00",
            "endDate":"2025-04-02T13:00:00"
        },
        "oog":{
            "startDate":"2025-04-02T13:00:00",
            "endDate":"2025-04-02T14:00:00"
        },
        "pop":{
            "startDate":"2025-04-02T14:00:00",
            "endDate":"2025-04-02T15:00:00"
        },
        "kat":{
            "startDate":"2025-04-02T15:00:00",
            "endDate":"2025-04-02T16:00:00"
        },
        "jas":{
            "startDate":"2025-04-02T16:00:00",
            "endDate":"2025-04-02T17:00:00"
        }
    },
    "2025-04-03":{
        "ijs":{
            "startDate":"2025-04-03T08:00:00",
            "endDate":"2025-04-03T09:00:00"
        },
        "hut":{
            "startDate":"2025-04-03T09:00:00",
            "endDate":"2025-04-03T10:00:00"
        },
        "gps":{
            "startDate":"2025-04-03T10:00:00",
            "endDate":"2025-04-03T11:00:00"
        },
        "kar":{
            "startDate":"2025-04-03T11:00:00",
            "endDate":"2025-04-03T12:00:00"
        },
        "bos":{
            "startDate":"2025-04-03T12:00:00",
            "endDate":"2025-04-03T13:00:00"
        },
        "pen":{
            "startDate":"2025-04-03T13:00:00",
            "endDate":"2025-04-03T14:00:00"
        },
        "wit":{
            "startDate":"2025-04-03T14:00:00",
            "endDate":"2025-04-03T15:00:00"
        },
        "eik":{
            "startDate":"2025-04-03T15:00:00",
            "endDate":"2025-04-03T16:00:00"
        },
        "bal":{
            "startDate":"2025-04-03T16:00:00",
            "endDate":"2025-04-03T17:00:00"
        }
    },
    "2025-04-04":{
        "mop":{
            "startDate":"2025-04-04T08:00:00",
            "endDate":"2025-04-04T09:00:00"
        },
        "bel":{
            "startDate":"2025-04-04T09:00:00",
            "endDate":"2025-04-04T10:00:00"
        },
        "zoo":{
            "startDate":"2025-04-04T10:00:00",
            "endDate":"2025-04-04T11:00:00"
        },
        "zak":{
            "startDate":"2025-04-04T11:00:00",
            "endDate":"2025-04-04T12:00:00"
        },
        "kip":{
            "startDate":"2025-04-04T12:00:00",
            "endDate":"2025-04-04T13:00:00"
        },
        "mos":{
            "startDate":"2025-04-04T13:00:00",
            "endDate":"2025-04-04T14:00:00"
        },
        "vla":{
            "startDate":"2025-04-04T14:00:00",
            "endDate":"2025-04-04T15:00:00"
        },
        "vlo":{
            "startDate":"2025-04-04T15:00:00",
            "endDate":"2025-04-04T16:00:00"
        },
        "uur":{
            "startDate":"2025-04-04T16:00:00",
            "endDate":"2025-04-04T17:00:00"
        }
    },
    "2025-04-22":{
        "wol":{
            "startDate":"2025-04-22T08:00:00",
            "endDate":"2025-04-22T09:00:00"
        },
        "gat":{
            "startDate":"2025-04-22T09:00:00",
            "endDate":"2025-04-22T10:00:00"
        },
        "gul":{
            "startDate":"2025-04-22T10:00:00",
            "endDate":"2025-04-22T11:00:00"
        },
        "ufo":{
            "startDate":"2025-04-22T11:00:00",
            "endDate":"2025-04-22T12:00:00"
        },
        "kei":{
            "startDate":"2025-04-22T12:00:00",
            "endDate":"2025-04-22T13:00:00"
        },
        "dak":{
            "startDate":"2025-04-22T13:00:00",
            "endDate":"2025-04-22T14:00:00"
        },
        "nek":{
            "startDate":"2025-04-22T14:00:00",
            "endDate":"2025-04-22T15:00:00"
        },
        "zin":{
            "startDate":"2025-04-22T15:00:00",
            "endDate":"2025-04-22T16:00:00"
        },
        "sla":{
            "startDate":"2025-04-22T16:00:00",
            "endDate":"2025-04-22T17:00:00"
        }
    },
    "2025-04-23":{
        "gom":{
            "startDate":"2025-04-23T08:00:00",
            "endDate":"2025-04-23T09:00:00"
        },
        "dag":{
            "startDate":"2025-04-23T09:00:00",
            "endDate":"2025-04-23T10:00:00"
        },
        "uil":{
            "startDate":"2025-04-23T10:00:00",
            "endDate":"2025-04-23T11:00:00"
        },
        "fan":{
            "startDate":"2025-04-23T11:00:00",
            "endDate":"2025-04-23T12:00:00"
        },
        "zon":{
            "startDate":"2025-04-23T12:00:00",
            "endDate":"2025-04-23T13:00:00"
        },
        "ree":{
            "startDate":"2025-04-23T13:00:00",
            "endDate":"2025-04-23T14:00:00"
        },
        "mug":{
            "startDate":"2025-04-23T14:00:00",
            "endDate":"2025-04-23T15:00:00"
        },
        "sap":{
            "startDate":"2025-04-23T15:00:00",
            "endDate":"2025-04-23T16:00:00"
        },
        "tak":{
            "startDate":"2025-04-23T16:00:00",
            "endDate":"2025-04-23T17:00:00"
        }
    },
    "2025-04-24":{
        "hip":{
            "startDate":"2025-04-24T08:00:00",
            "endDate":"2025-04-24T09:00:00"
        },
        "ton":{
            "startDate":"2025-04-24T09:00:00",
            "endDate":"2025-04-24T10:00:00"
        },
        "gsm":{
            "startDate":"2025-04-24T10:00:00",
            "endDate":"2025-04-24T11:00:00"
        },
        "gom":{
            "startDate":"2025-04-24T11:00:00",
            "endDate":"2025-04-24T12:00:00"
        },
        "lol":{
            "startDate":"2025-04-24T12:00:00",
            "endDate":"2025-04-24T13:00:00"
        },
        "mop":{
            "startDate":"2025-04-24T13:00:00",
            "endDate":"2025-04-24T14:00:00"
        },
        "dag":{
            "startDate":"2025-04-24T14:00:00",
            "endDate":"2025-04-24T15:00:00"
        },
        "opa":{
            "startDate":"2025-04-24T15:00:00",
            "endDate":"2025-04-24T16:00:00"
        },
        "mos":{
            "startDate":"2025-04-24T16:00:00",
            "endDate":"2025-04-24T17:00:00"
        }
    }
});
